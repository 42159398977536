import type { AppRouter } from '@foodlista/backend'
import { createTRPCClient, httpBatchLink } from '@trpc/client'
import { getTRPCURL } from '~/domains/trpc/utils'

export default defineNuxtPlugin((nuxtApp) => {
  const trpcURL = getTRPCURL()

  const customFetch: typeof fetch = (url, init) => {
    // eslint-disable-next-line ts/ban-ts-comment
    // @ts-expect-error
    const locale = nuxtApp.$i18n.locale

    const ssrEvent = nuxtApp.ssrContext?.event
    const ssrHeaders = Array.from(ssrEvent?.headers?.entries() ?? []).reduce((acc, [key, value]) => {
      return {
        ...acc,
        [key]: value,
      }
    }, {})

    return fetch(url, {
      ...init,
      credentials: 'include',
      headers: {
        ...ssrHeaders,
        ...init?.headers,
        'accept-language': locale.value,
      },
    })
  }

  const trpc = createTRPCClient<AppRouter>({
    links: [
      httpBatchLink({
        url: trpcURL,
        fetch: customFetch,
      }),
    ],
  })

  return {
    provide: {
      trpc,
    },
  }
})
