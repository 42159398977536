import { defineCanFunction } from '@foodlista/access-control'
import { useQuery } from '@tanstack/vue-query'

export const USERS_CACHE_KEYS = {
  userProfile: ['users', 'me'],
  restaurants: ['users', 'me', 'restaurants'],
  ownedRestaurants: ['users', 'me', 'restaurants', 'owned'],
  invitations: ['users', 'me', 'invitations'],
  identities: ['users', 'me', 'identities'],
}

export function useGetUserProfile() {
  const { $trpc } = useNuxtApp()

  const queryResult = useQuery({
    queryFn: () => $trpc.auth.me.query(),
    queryKey: USERS_CACHE_KEYS.userProfile,
  })

  const userWithAbilities = computed(() => {
    const user = queryResult.data.value

    if (!user) {
      return null
    }

    const can = defineCanFunction(user.abilities || [])

    return {
      ...user,
      can,
    }
  })

  return {
    ...queryResult,
    data: userWithAbilities,
  }
}

export function useGetCurrentUserIdentities() {
  const { $trpc } = useNuxtApp()
  return useQuery({
    queryFn: () => $trpc.users.me.identities.list.query(),
    queryKey: USERS_CACHE_KEYS.identities,
  })
}

export function useGetCurrentUserInvitations() {
  const { $trpc } = useNuxtApp()
  return useQuery({
    queryKey: USERS_CACHE_KEYS.invitations,
    queryFn: () => $trpc.users.me.invitations.query(),
  })
}

export function useGetUserRestaurants() {
  const { $trpc } = useNuxtApp()

  return useQuery({
    queryFn: () => $trpc.users.me.restaurants.all.query(),
    queryKey: USERS_CACHE_KEYS.restaurants,
  })
}

export function useGetUserOwnedRestaurants() {
  const { $trpc } = useNuxtApp()

  return useQuery({
    queryFn: () => $trpc.users.me.restaurants.owned.query(),
    queryKey: USERS_CACHE_KEYS.ownedRestaurants,
  })
}
