import type { AppRouter } from '@foodlista/backend'
import type { H3Event } from 'h3'
import { createTRPCClient, httpBatchLink } from '@trpc/client'
import { getBackendUrl } from '../common/utils'

export function getTRPCCLient(event: H3Event) {
  const trpcURL = getTRPCURL()

  return createTRPCClient<AppRouter>({
    links: [
      httpBatchLink({
        url: trpcURL,
        headers: event.headers,
      }),
    ],
  })
}

export function getTRPCURL() {
  return `${getBackendUrl()}/trpc`
}
