import { useGetUserProfile } from '~/domains/users/queries'

export function usePostHogClient() {
  const { $posthog } = useNuxtApp()

  const { data: profile } = useGetUserProfile()

  watch(profile, () => {
    if (!profile.value) {
      return
    }

    const posthog = $posthog?.()

    const user = profile.value

    posthog?.identify(user.id, {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
    })
  })

  return $posthog?.() || undefined
}
